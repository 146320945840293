<template>
	<div>
		<el-form ref="_importExcelForm" :model="dataSource">
			<!-- <el-form-item>
				<el-col :span="3" class="form-title"><span style="color:red;">*</span>货主名称：</el-col>
				<el-col :span="7">
					<el-form-item prop="CustomerName">
						<el-select v-model="dataSource.CustomerCode" collapse-tags placeholder="请选择" :maxlength="200"
							readonly @change="changeCustomer">
							<el-option v-for="item in dataSource.CustomerList" :key="item.Key" :label="item.Value"
								:value="item.Key">
							</el-option>
						</el-select>

					</el-form-item>
				</el-col>
				<div>
					<el-col :span="3" class="form-title"><span style="color:red;">*</span>货主编码：</el-col>
					<el-col :span="7">
						<el-form-item prop="CustomerCode">
							<el-input v-model="dataSource.CustomerCode" :maxlength="30" :minlength="4" :disabled="1"
								placeholder="客户编码"></el-input>
						</el-form-item>
					</el-col>
				</div>
			</el-form-item> -->
			<el-form-item>
				<el-col :span="3" class="form-title">
					<span style="color:red;">*</span>导入模板：
				</el-col>
				<el-col :span="7">
					<el-form-item>
						<el-select v-model="upLoadData.TemplateID" placeholder="选择模板" class="select-import">
							<el-option v-for="item in templateList" :key="item.Key" :label="item.Value"
								:value="item.Key">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="3" style="text-align:letf;">
					<el-button type="text" style="color:#1874CD;cursor:pointer;margin-left: 5px;"
						icon="el-icon-download" @click="DownloadFile">下载模板</el-button>
				</el-col>
			</el-form-item>
			<el-form-item>
				<el-col :span="3" class="form-title">
					<span style="color:red;">*</span>文件选择：
				</el-col>
				<el-col :span="10">
					<el-upload ref="upload" class="upload-demo" :action="importAction" :on-preview="handlePreview"
						:on-remove="handleRemove" :before-remove="beforeRemove" :data="upLoadData" multiple :limit="1"
						:on-exceed="handleExceed" :onSuccess="uploadSuccess" :before-upload="beforeUpload"
						accept=".xlsx,.xls,.csv" :file-list="fileList">
						<el-button slot="trigger" size="small" type="primary" icon="el-icon-upload">选取文件</el-button>
						<div slot="tip" class="el-upload__tip"><span v-text="tipmsg"></span></div>
						<!-- <el-button size="small" type="primary">点击上传</el-button>
                        <div slot="tip" class="el-upload__tip"><span v-text="tipmsg"></span></div> -->
					</el-upload>
				</el-col>
			</el-form-item>


			<!--      <el-form-item>
                <el-col :span="3" class="form-title">
                    订单设置：
                </el-col>
                <el-col :span="10">可填写以下信息，对导入订单进行统一设置</el-col>
            </el-form-item>
            <el-form-item>
                <el-col :span="3" class="form-title">
                    合并商品：
                </el-col>
                <el-col :span="2">
                    <el-tooltip class="item" effect="dark" content="同一订单合并商品相同的行明细" placement="top">
                        <el-switch
                            v-model="upLoadData.IsMergeProduct"
                            active-color="#13ce66"
                            inactive-color="#ff4949">
                        </el-switch>
                    </el-tooltip>
                </el-col>
            </el-form-item>
            <el-form-item>
                <el-col :span="3" class="form-title">
                    订单类型：
                </el-col>
                <el-col :span="4">
                    <el-form-item>
                        <el-select v-model="upLoadData.OrderTypeCode" placeholder="选择类型" class="select-import">
                            <el-option v-for="item in orderTypeList" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="3" class="form-title">
                    仓库：
                </el-col>
                <el-col :span="4">
                    <el-form-item>
                        <el-select v-model="upLoadData.WarehouseNo" placeholder="选择仓库" class="select-import">
                            <el-option v-for="item in warehouseList" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="3" class="form-title">
                    承运商：
                </el-col>
                <el-col :span="4">
                    <el-form-item>
                        <el-select v-model="upLoadData.MemberCode" :disabled="isDisplay && !upLoadData.DeliveryWayCode" placeholder="选择承运商" class="select-import">
                            <el-option v-for="item in carrierList" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="1">
                    <el-tooltip class="item" v-show="isDisplay" effect="dark" content="承运商跟随配送方式变化" placement="top">
                        <i class="el-icon-info"></i>
                    </el-tooltip>
                </el-col>
            </el-form-item>
            <el-form-item v-show="isDisplay">
                <el-col :span="3" class="form-title">
                    配送方式：
                </el-col>
                <el-col :span="4">
                    <el-form-item>
                        <el-select v-model="upLoadData.DeliveryWayCode" placeholder="选择配送" class="select-import"
                            @change="deliveryWayChange(upLoadData.DeliveryWayCode)">
                            <el-option v-for="item in deliveryWayCodeList" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="3" class="form-title">
                    店铺：
                </el-col>
                <el-col :span="4">
                    <el-form-item>
                        <el-select v-model="upLoadData.ShopNo" placeholder="选择店铺" class="select-import">
                            <el-option v-for="item in shopNoList" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="3" class="form-title">
                    交易平台：
                </el-col>
                <el-col :span="4">
                    <el-form-item>
                        <el-select v-model="upLoadData.SourceCode" placeholder="选择平台" class="select-import">
                            <el-option v-for="item in sourceList" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-form-item>  -->
			<!-- <el-form-item v-show="isDisplay">
                <el-col :span="3" class="form-title">
                    运费支付方式：
                </el-col>
                <el-col :span="4">
                    <el-form-item>
                        <el-select v-model="upLoadData.FreightPayTypeCode" placeholder="选择运费支付方式" class="select-import">
                            <el-option v-for="item in freightPayTypeCodeList" :key="item.Key" :label="item.Value" :value="item.Key">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>    
            </el-form-item> -->
		</el-form>
	</div>

</template>
<script>
import sha from '../../../../scripts/sha.js';
export default {
	data() {
		return {
			/* 	importAction: this.Utils.getDomain() + "omsapi/order/orderimport/uploadorderdatatemplate?currentType=" +
					this.$store.state.global.currentType + "&currentCCode=" + this.$store.state.global.currentCCode, */

			importAction: window.AppConfig.apiCustomerUrl + "omsapi/files/upload?token=" + this.Utils.getToken(),
			upLoadData: {
				TemplateID: '',
				OrderTypeCode: '',
				IsMergeProduct: false,
				DeliveryWayCode: '',
				WarehouseNo: '',
				MemberCode: '',
				ShopNo: '',
				OrderImportID: '',
				SourceCode: '',
				FreightPayTypeCode: '',
				isUpload: false,
				OrderType: '',
				FileName: "",
				FileNamePath: ""

			},
			templateList: [],
			orderTypeList: [],
			deliveryWayCodeList: [],
			warehouseList: [],
			totalCarrierList: [],
			carrierList: [],
			shopNoList: [],
			sourceList: [],
			freightPayTypeCodeList: [],
			tipmsg: "",
			fileList: [],
			upnumber: 0,
			TemplateIDs: [],
			UploadData: {},
			isDisplay: false,
		};
	},
	props: {
		orderType: 0,
		dataSource: {
			CustomerCode: "",
			CustomerList: []

		}
	},
	mounted() {
		// this.loadImporType(this.orderType);
		if (this.orderType == 1) {
			this.upLoadData.OrderType = '100';
			this.isDisplay = false;
		} else {
			this.upLoadData.OrderType = '200';
			this.isDisplay = true;
		}
		this.upnumber = this.fileList.length;
		this.loadImporType(null, this.orderType);
		// this.loadTemplateListByBusinessType(this.orderType);
		// this.loadOrderTypeList(this.orderType);
		// this.loadCarrierList();
		// this.loadWarehouseList();
		// if (this.orderType == 1) {
		//     this.isDisplay = false;
		// }
		// else {
		//     this.isDisplay = true;
		//     this.loadDeliveryWayCodeList();
		//     this.loadShopNoList();
		//     this.loadSourceList();
		// }
		//this.loadFreightPayTypeCodeList();
	},
	methods: {
		submitUpload() {
			this.$refs.upload.submit();
		},
		deliveryWayChange(deliveryWay) {
			//重置承运商信息
			this.upLoadData.MemberCode = "";
			this.carrierList = this.totalCarrierList;
			//this.loadCarrierList();
			var SelfWay = []; //自提
			var CityDeliverWay = []; //城配
			var ExpressWay = []; //快递
			var ExpDeliverWay = []; //快运
			for (var i = 0; i < this.carrierList.length; i++) {
				if (this.carrierList[i].MemberType == 1) { //城配
					CityDeliverWay.push(this.carrierList[i]);
				} else if (this.carrierList[i].MemberType == 2) { //快递
					ExpressWay.push(this.carrierList[i]);
				} else if (this.carrierList[i].MemberType == 3) { //快运
					ExpDeliverWay.push(this.carrierList[i]);
				} else if (this.carrierList[i].MemberType == 4) {
					SelfWay.push(this.carrierList[i]);
				}
			}
			if (deliveryWay == 100) { //自提
				this.carrierList = SelfWay;
			} else if (deliveryWay == 200) { //快递
				this.carrierList = ExpressWay;
			} else if (deliveryWay == 300) { //快运
				this.carrierList = ExpDeliverWay;
			} else if (deliveryWay == 400) { //城配
				this.carrierList = CityDeliverWay;
			}
		},
		loadImporType(code, orderType) {
			this.$ajax.send("omsapi/template/getimporttemplatebybusinesstype", "get", {
				customerCode: '',
				businessType: orderType
			}, (data) => {
				if (data.Result != null && data.Result != "") {
					this.templateList = data.Result;
				}

			});
		},
		handleRemove(file, fileList) {
			if (file && file.status === "success") {
				this.$ajax.send("omsapi/order/orderimport/removefile", "get", {
					id: file.response.Result
				}, (data) => {
					if (!data.IsSuccess) {
						this.Utils.messageBox(data.OperationDesc, "error");
						return false;
					} else {
						if (fileList.length == 0) {
							this.tipmsg = "";
						} else {
							this.tipmsg = "上传成功" + fileList.length + "个文件";
						}
						this.TemplateIDs.remove(file.response.Result);
					}
				});
			}
			//console.log(file, fileList);
		},
		handlePreview(file) {
			console.log(file);
		},
		handleExceed(files, fileList) {
			this.$message.warning(
				`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
		},
		beforeRemove(file, fileList) {
			if (file && file.status === "success") {
				return this.$confirm("确定移除 " + file.name + "？");
			}
		},
		// 上传成功后的回调
		uploadSuccess(response, file, fileList) {
			if (response.IsSuccess) {
				this.tipmsg = "上传成功" + fileList.length + "个文件";
			} else {
				this.tipmsg = response.OperationDesc;
			}
			this.UploadData = response.Result;
			this.TemplateIDs.push(response.Result.Url);
			//this.upLoadData.OrderImportID = response.Result;
			this.Utils.messageBox(response.OperationDesc, response.IsSuccess ? "success" : "error");
		},
		// 上传前对文件的大小的判断
		beforeUpload(file) {
			// if (this.dataSource.CustomerCode == null || this.dataSource.CustomerCode == '') {
			// 	this.Utils.messageBox("未选择货主。", "error");
			// 	return;
			// }
			if (this.upLoadData.TemplateID == null || this.upLoadData.TemplateID == '' || this.upLoadData.TemplateID ==
				this.Utils.emptyGuid) {
				this.Utils.messageBox("请选择上传模板。", "error");
				return false;
			}
			var extension = file.name.substring(file.name.lastIndexOf('.') + 1);
			var allowExtension = ['xls', 'xlsx', 'csv'];
			const isLt2M = file.size / 1024 / 1024 < 50;
			if (allowExtension.indexOf(extension.toLowerCase()) < 0) {
				this.Utils.messageBox('上传模板只能是 xls、xlsx、csv格式!', "error");
				return false;
			}
			if (!isLt2M) {
				this.Utils.messageBox("上传模板大小不能超过 50MB!", "error");
				return false;
			}
			return extension && isLt2M;
		},
		// 上传错误
		uploadError(response, file, fileList) {
			this.Utils.messageBox("上传失败，请重试！", "error");
		},
		//保存数据
		saveFlie(options, isUpload) {
			if (this.TemplateIDs && this.TemplateIDs.length > 0) {
				/* 	this.TemplateIDs.forEach(element => {
						if (element == null || element == '') {
							this.Utils.messageBox("请选择文件。", "error");
							return;
						}
						this.upLoadData.isUpload = isUpload;
						//this.upLoadData.OrderImportID = element;
						this.upLoadData.FileName=element;
						this.$ajax.send("omsapi/order/importorderexcel", "post", this.upLoadData, (
							data) => {
							this.upLoadData.OrderImportID = data.Result;
						});
					});
					 */
				//this.upLoadData.CustomerCode = this.dataSource.CustomerCode;
				this.upLoadData.isUpload = isUpload;
				//this.upLoadData.OrderImportID = element;
				this.upLoadData.FileName = this.UploadData.FileName;
				this.upLoadData.FilePath = this.UploadData.Url;
				this.$ajax.send("omsapi/order/importorderexcel", "post", this.upLoadData, (
					data) => {
					this.upLoadData.OrderImportID = data.Result;
					if(data.IsSuccess){
						options.visible = false;
					}
				});

			} else {
				return this.Utils.messageBox("请先上传文件.", "error");;
			}
		},
		DownloadFile: function () {
			if (this.upLoadData.TemplateID == null || this.upLoadData.TemplateID == '' || this.upLoadData
				.TemplateID == this.Utils.emptyGuid) {
				this.Utils.messageBox("请选择文件。", "error");
				return;
			}
			this.$ajax.send("omsapi/template/search", "get", {
				id: this.upLoadData.TemplateID
			}, (data) => {
				if (data.Result != null) {
					this.Utils.exportUrl("omsapi/files/download?file=标准订单导入模板.xlsx&url="+data.Result.ServerFileName + "&token=" + sha.getToken1());
				}
			});
		}
	}
}
</script>
<style scope>
input[type="file"] {
	display: none;
}

/*.el-upload-list {
        width: 200px;
    }

    .el-select {
        width: 135px;
    }*/
</style>